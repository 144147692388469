.bottomMenu__bar {
  //background-color: $primary-color;
  position: relative;
  height: 20px;
  cursor: ns-resize;
}
.bottomMenu__line1, .bottomMenu__line2 {
  background-color: rgba(white, 0.9);
  height: 3px;
  width: 40px;
  position: absolute;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}

.bottomMenu__line1 {
  top: 5px;
}

.bottomMenu__line2 {
  top: 12px;
}

.bottomMenuPadding {
  width:100%;
  height: 10px;
  background-color: rgba(#000, 0.87);
  z-index: 101;
  position: fixed;
  bottom: 0;
    //iPhone X
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      height: 20px;
    } 
}