.bottomMenu {
  color: white;
  position: fixed;
  z-index: 100;
  bottom:-1px;
  left: 0;
  width: 100%;
  
  //background-color: $primary-color;
  //transform: translate(0px, calc(100% - 30px));
  //position: absolute;

  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    //bottom: 20px;
  }

  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    max-width: 400px;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      max-width: 650px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }
}


.bottomMenu__li {
  transition: all 0.5s ease-out;
  &:hover, &:focus, &.selected {
    background-color: rgba(#000, 0.1);
  }
}

.bottomMenu__link {

  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0.7;
  
  //padding-bottom: 10px;
  font-family: $family-header;

  * {
    position: relative;
    z-index: 100;
  }

  .icon {
    width: 32px;
    height: 32px;
    @include bg-img();
    margin: auto;
    transition: all 0.3s ease-out;
  }

  div {
    font-size: 12px;
    letter-spacing: 0.5px;
  line-height: 1;
  margin-top: 0.3rem;
  }

  &:hover,
  &:focus,
  &.selected {
    //transform: translate(0, -2px);
    color: white;
    text-decoration: none;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 5px;

    .icon {
      width: 40px;
      height: 40px;
    }

    div {
      font-size: 13px;
      letter-spacing: 0.5px;
    }
  }
}


.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {}

.bottomMenu__link--0 .icon {
  background-image: url('/assets/navicons/products.png');
}

.bottomMenu__link--0:hover .icon,
.bottomMenu__link--0.selected .icon {
  //background-image: url('/assets/navicons/products-nav-hover.png');
}

.bottomMenu__link--1 .icon {
  background-image: url('/assets/navicons/contact.png');
}

.bottomMenu__link--2 .icon {
  background-image: url('/assets/navicons/videos.png');
}

.bottomMenu__link--3 .icon {
  background-image: url('/assets/navicons/warranty.png');
}

.bottomMenu__link--4 .icon {
  background-image: url('/assets/navicons/blog.png');
}


//https://tympanus.net/Development/IconHoverEffects/#set-8

nav.bottomMenu{
  background-image: url('/assets/logos/base-masthead.png');
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  // @include media-breakpoint-up(sm) { // 576px and up
  //   height: 350px;
  // }
  // @include media-breakpoint-up(md) { // 768px and
  //   height: 400px;
  // }
  // @include media-breakpoint-up(lg) { // 992px and up
  //   height: 450px;
  // }
  // @include media-breakpoint-up(xl) { // 1200px and up
  //   height: 500px;
  // }
  // @media (min-width: 1601px) {
  //   height: 50px;
  // }
  // @media (min-width: 1901px) {
  //   height: 600px;
  // }
  // @media (min-width: 2201px) {
  //   height: 700px;
  // }
}