.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;

    .pageTile_details:before {
      background: $primary-color;
    }
    .pageTile__title,
    .pageTile_desc {
      //color: white;
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: $font-color;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: $font-color;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 0.5rem 3rem 0.8rem 1rem;
  text-align: left;
}


.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}
